import React, { useEffect, useState } from "react";
import queryString from "query-string";
import styled from "styled-components";
import { PageProps } from "gatsby";

import { Button, Container, H3, Separator } from "@util/standard";
import { InstantSearch, connectHits, Index } from "react-instantsearch-dom";
import { getIndexName, isBrowser, searchClient } from "@util/helper";
import { useStore } from "@state/store";
import PreviewAll from "@shared/previewAll";
import ProductPreview from "@shared/productPreview";
import BlogPreview from "@components/blogs/blogPreview";
import { SearchBar } from "@global";
import Layout from "@shared/layout";
import { TABLET_BREAKPOINT } from "@util/constants";

const BlogsContainer = styled(Container)`
  > div {
    margin-bottom: 30px;
  }
`;

const ProductContainer = styled(Container)`
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    align-self: center;
    width: calc(100% + 10px);
    justify-content: center;
  }
`;

const StyledButton = styled(Button)`
  margin: 25px 0 50px;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 25px auto 50px;
  }
`;

const Search = ({ location }: PageProps) => {
  if (!isBrowser()) return null;

  const { searchParams } = useStore();
  const [shownNumber, setShownNumber] = useState(15);
  const [shownBlogs, setShownBlogs] = useState(15);
  const [recipeSearch, setRecipeSearch] = useState(false);

  const searchState = {
    query: searchParams.searchString,
    page: 0,
  };
  //Check url params
  useEffect(() => {
    let parsedSearch = queryString.parse(location.search);
    if (parsedSearch && parsedSearch.search) {
      let searchString: string = parsedSearch.search?.replaceAll("-", " ");
      const isAll = parsedSearch.all === "1";

      if (isAll) {
        setRecipeSearch(false);
      } else {
        setRecipeSearch(true);
      }

      //Set global search state
      useStore.setState({
        searchParams: {
          searchString: searchString,
          hasSearched: true,
          globalSearch: true,
        },
      });
    } else {
      setRecipeSearch(false);
      useStore.setState({
        searchParams: {
          searchString: "",
          hasSearched: false,
          globalSearch: false,
        },
      });
    }
  }, [location]);

  useEffect(() => {
    return () => {
      //Unmount
      useStore.setState({
        searchParams: {
          searchString: "",
          hasSearched: false,
          globalSearch: false,
        },
      });
    };
  }, []);

  const ProductHits = ({ hits }: any) => {
    if (hits == null || hits.length < 1) {
      return <>No products found</>;
    }
    return (
      <>
        <ProductContainer
          flexWrap="wrap"
          justifyContent="flex-start"
          className="search--wrapper"
        >
          {hits.slice(0, shownNumber).map((hit: any, i: number) => {
            return (
              <Container
                margin="0px 25px 25px 0px"
                mobileMargin="0 10px 10px 0"
                key={i}
              >
                <ProductPreview
                  key={hit.id}
                  noMargin
                  product={hit}
                  inSearch={true}
                  isGift={hit?.content?.shopify?.isGift ?? false}
                />
              </Container>
            );
          })}
        </ProductContainer>
        {shownNumber < hits.length && (
          <StyledButton
            onClick={() => {
              setShownNumber(pre => pre + 15);
            }}
          >
            Load More
          </StyledButton>
        )}
      </>
    );
  };
  const CustomProductHits = connectHits(ProductHits);

  const RecipeHits = ({ hits }: any) => {
    if (hits == null || hits.length < 1) {
      return <>No recipes found</>;
    }

    return (
      <PreviewAll
        algoliaRecipes={hits}
        hideSearchResults
        iban={process.env.GATSBY_IBAN}
      />
    );
  };
  const CustomRecipeHits = connectHits(RecipeHits);

  const BlogHits = ({ hits }: any) => {
    if (hits == null || hits.length < 1) {
      return <>No posts found</>;
    }
    return (
      <>
        <BlogsContainer flexWrap="wrap" justifyContent="flex-start">
          {hits.slice(0, shownBlogs).map((hit: any, i: number) => {
            return <BlogPreview blog={hit} key={i} />;
          })}
        </BlogsContainer>
        {shownBlogs < hits.length && (
          <StyledButton
            onClick={() => {
              setShownBlogs(pre => pre + 15);
            }}
          >
            Load More
          </StyledButton>
        )}
      </>
    );
  };

  const CustomBlogHits = connectHits(BlogHits);

  return (
    <>
      <Layout>
        <Container
          margin="200px 10% 80px 10%"
          mobileWidth="90%"
          mobileMargin="100px auto 55px auto"
        >
          <InstantSearch
            searchClient={searchClient}
            indexName={getIndexName("products")}
            searchState={searchState}
          >
            <Container
              flexDirection="column"
              margin="auto"
              justifyContent="center"
              width="100%"
            >
              <Container flexDirection="column" maxWidth="600px" margin="auto">
                <SearchBar />

                {searchParams.hasSearched ? (
                  <H3 margin="0px 0px 40px 0px" fontSize={20}>
                    Search results for '{searchParams.searchString}'
                  </H3>
                ) : (
                  <Container width="100%" height="200px">
                    <H3 fontSize={20}>Please enter a search term</H3>
                  </Container>
                )}
              </Container>

              {searchParams.hasSearched && (
                <>
                  {!recipeSearch && (
                    <>
                      <Index
                        indexName={getIndexName("products")}
                      >
                        <H3 margin="0px 0px 30px 0px" fontSize={23}>
                          Product Results
                        </H3>
                        <CustomProductHits />
                      </Index>
                      <Separator margin="0 0 60px" />
                    </>
                  )}

                  <Index indexName={getIndexName("recipes")}>
                    <H3 margin="0px 0px 30px 0px" fontSize={23}>
                      Recipe Results
                    </H3>

                    <CustomRecipeHits />
                  </Index>
                  {!recipeSearch && (
                    <>
                      <Separator margin="60px 0px" />
                      <Index indexName={getIndexName("blogs")}>
                        <H3 margin="0px 0px 30px 0px" fontSize={23}>
                          Food Blog Results
                        </H3>

                        <CustomBlogHits />
                      </Index>
                    </>
                  )}
                </>
              )}
            </Container>
          </InstantSearch>
        </Container>
      </Layout>
    </>
  );
};

export default Search;
